<template>
    <v-content>
        <Server/>
    </v-content>
</template>

<script>
  import Server from '../components/Server'

  export default {
    name: 'server',
    components: {
      Server
    }
  }
</script>
